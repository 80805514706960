.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.App h1 {
    color: white;
    font-family: 'Inconsolata', monospace;
    letter-spacing: 0.2em;
    transition: text-shadow 750ms ease-in-out 0ms;
    user-select: none;
}

.App h1:hover {
    text-shadow: 0 0 0.5em white;
}
